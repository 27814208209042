import { inject } from '@angular/core';

import { Actions, ofType, createEffect } from '@ngrx/effects';
import * as themeActions from '../actions/theme.action';

import { tap } from 'rxjs/operators';

export const setTheme$ = createEffect(
  (actions$ = inject(Actions)) => {
    return actions$.pipe(
      ofType(themeActions.SetTheme),
      tap(({ theme }) => {
        document.body.setAttribute('data-bs-theme', theme);
      })
    );
  },
  { functional: true, dispatch: false }
);
