import { inject } from '@angular/core';
import { Router } from '@angular/router';
import { Location } from '@angular/common';

import { Actions, ofType, createEffect } from '@ngrx/effects';
import * as RouterActions from '../actions/router.action';

import { tap } from 'rxjs/operators';

export const navigate$ = createEffect(
  (actions$ = inject(Actions), router = inject(Router)) => {
    return actions$.pipe(
      ofType(RouterActions.Go),
      tap(({ path, queryParams, extras }) => {
        router.navigate(path, { queryParams, ...extras });
      })
    );
  },
  { functional: true, dispatch: false }
);

export const navigateBack$ = createEffect(
  (actions$ = inject(Actions), location = inject(Location)) => {
    return actions$.pipe(
      ofType(RouterActions.Back),
      tap(() => location.back())
    );
  },
  { functional: true, dispatch: false }
);

export const navigateForward$ = createEffect(
  (actions$ = inject(Actions), location = inject(Location)) => {
    return actions$.pipe(
      ofType(RouterActions.Forward),
      tap(() => location.forward())
    );
  },
  { functional: true, dispatch: false }
);
